import logo from './logo.svg';
import './App.css';
import './assets/styles.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import HomeScreen from "./screens/HomeScreen";
import { inject } from '@vercel/analytics';

const App = () => {
  inject();

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<HomeScreen/>}/>
      </Routes>
    </Router>
  );
};

export default App;