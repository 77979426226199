import React from "react";
import Logo from "../assets/LogoSimpleLight.svg";

const Footer = () => {

    return (
        <div className="footer">
            <div className="bg-darkMain text-center lg:text-left block">
                <div className="text-white text-center p-4 block">
                    <img alt="Novusa Galdi no Vaidavas" width="145" height="45" className="mx-auto h-12" src={Logo}></img>
                </div>
            </div>
        </div>
    )
}

export default Footer;