import Galds from "./assets/products/galds.jpg";
import Kajas from "./assets/products/kajas.jpg";
import Kijas from "./assets/products/kijas-new.jpg";
import KijasDouble from "./assets/products/kijas-double.jpg";
import Ripas from "./assets/ripas-kaulini.jpg";
import BernuGalds from "./assets/bernu-galds.jpg";
import Kastes from "./assets/products/kastes.jpg";

export const products = [
    {
        name: 'Novusa Galds',
        description: '<p>Galda virsma ir izgatavota no 18mm bērza saplākšņa. Slīpēta gan pirms lakošanas, gan pēc lakošanas, gan starp lakošanas reizēm. Virsma tiek vienreiz gruntēta un četras reizes lakota. Borti ir izgatavoti no bērza un arī tiek četras reizes lakoti un slīpēti.</p>',
        price: '205€',
        image: 'galds.jpg'
    },
    {
        name: 'Novusa galda kājas',
        description: '<p>Novusa galda kājas tiek gatavotas no koka latām 70x45x700 mm un 9mm saplākšņa. Kājas var ātri salikt un arī izjaukt. Priekš līmeņošanas kāju vertikālajās daļās ir iestrādātas skrūves, kuras ļauj viegli nolīmeņot galdu.</p>',
        price: '45€',
        image: 'kajas.jpg'
    },
    {
        name: 'Novusa aksesuāri',
        description: '<p>Kiju futrālis, kurš ir izgatavots no koka un apvilkts ar dermantīnu, lai pasargātu Jūsu kijas no mitruma. Pieejami dažāda garuma futrāļi un iespējams pasūtīt tos arī specifiski jūsu vajadzībām un gaumei.</p>',
        price: '35€ - 55€',
        image: 'kastes.jpg'
    },
    {
        name: 'Priedes Kijas',
        description: '<p>Apaļas - <span class="price">20€</span></p><p>Ar astoņām kantēm - <span class="price">30€</span></p>',
        price: false,
        image: 'kijas-new.jpg'
    },
    {
        name: 'Kijas ar cēlkoku',
        description: '<p>Kijas ar cēlkoku elementiem.</p>',
        price: '40€',
        image: 'kijas-double.jpg'
    }
]

export const newProducts = [
    {
        name: 'Ripas un kauliņi',
        description: '<p>Ripa - 20€</p><p>Kauliņi (16 melni, 16 sarkani) - 30€</p>',
        price: false,
        image: 'ripas-kaulini.jpg'
    },
    {
        name: 'Komplekts bērniem',
        description: '<p>Komplektā</p><p>Galda virsma 75x75cm</p><p>Kājas 45-50cm augstas</p><p>Kauliņi (16 melni, 16 sarkani)</p><p>Kijas 2 gb.</p><p>Ripas 2gb.</p>',
        price: '200€',
        image: 'bernu-galds.jpg'
    }
]