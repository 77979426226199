import React, { useState } from "react";
import desktop from "../assets/desktop.jpg";
import mobile from "../assets/mobile.jpg";
import Header from "../component/Header";
import Footer from "../component/Footer";
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react' // import from 'keen-slider/react.es' for to get an ES module
import { Helmet } from 'react-helmet';
import { SpeedInsights } from "@vercel/speed-insights/react";
import { newProducts, products } from "../products.config";
import BuyButton from "../component/BuyButton";

const HomeScreen = () => {
  const [loaded, setLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0)
  const [sliderCurrentSlide, setSliderCurrentSlide] = useState(0)

  const [keenRef, keenInstanceRef] = useKeenSlider(
    {
      loop: true,    
      slidesPerView: 1,
      created() {
        setLoaded(true)
      }
    },
    [
      (slider) => {
        let timeout
        let mouseOver = false
        function clearNextTimeout() {
          clearTimeout(timeout)
        }
        function nextTimeout() {
          clearTimeout(timeout)
          if (mouseOver) return
          timeout = setTimeout(() => {
            slider.next()
          }, 2000)
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true
            clearNextTimeout()
          })
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false
            nextTimeout()
          })
          nextTimeout()
        })
        slider.on("dragStarted", clearNextTimeout)
        slider.on("animationEnded", nextTimeout)
        slider.on("updated", nextTimeout)
      },
    ]
  )


  const [sliderRef, sliderInstanceRef] = useKeenSlider(
    {
      loop: true,    
      slidesPerView: 1,
      centered: true,
      created() {
        setLoaded(true)
      },
      breakpoints: {
        '(min-width: 768px)': {
          slides: {
            perView:2,
            spacing: 12
          },
          loop: true
        },
        '(min-width: 1280px)': {
          slides: {
            perView:4,
            spacing: 12
          },
          loop: false
        }
      }
    },
    [
      (slider) => {
        let timeout
        let mouseOver = false
        function clearNextTimeout() {
          clearTimeout(timeout)
        }
        function nextTimeout() {
          clearTimeout(timeout)
          if (mouseOver) return
          timeout = setTimeout(() => {
            slider.next()
          }, 2000)
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true
            clearNextTimeout()
          })
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false
            nextTimeout()
          })
          nextTimeout()
        })
        slider.on("dragStarted", clearNextTimeout)
        slider.on("animationEnded", nextTimeout)
        slider.on("updated", nextTimeout)
      },
    ]
  )

  return (
    <div>
      <SpeedInsights />
      <Header />
      <div className="content px-2 md:px-0 lg:px-0">
        <div className="mb-4 main-photo mx-[-16px] md:mx-0 rounded-0 relative min-h-[calc(100vw*(1280/1920))] md:min-h-[calc(100vw*(400/1280))]">
            <picture className="md:rounded-lg block mx-auto">
              <source srcset={desktop} media="(min-width: 768px)"></source>
              <img className="w-full" src={mobile} alt="" />
            </picture>
            <div className="absolute text-center text-white top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 w-full">
              <p className="mb-3 font-protest text-[42px] lg:text-[64px] leading-[42px] lg:leading-[64px] [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)]">NOVUSS</p>
              <p className="mt-3 font-protest text-[16px] lg:text-[24px] leading-[32px] lg:leading-[32px] [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)]">Aizraujoša koncentrēšanās un precizitātes spēle</p>
            </div>
        </div>
        <section id="products" className="md:gap-4 md:px-2 md:flex flex-wrap block mt-8 mb-10 max-w-screen-lg mx-auto buy-section">
          <div className="w-full block rounded-lg shadow bg-darkMain news-block mb-4 relative">
            <p className="font-protest text-[30px] leading-[30px] text-white absolute left-1/2 top-[-15px] -translate-x-1/2 [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)] z-10 bg-darkMain rounded-lg p-1">JAUNUMI</p>
            <div ref={keenRef} className="keen-slider rounded-lg w-full z-2">
              {newProducts.map((newProduct, index) =>
                <div key={index} className="keen-slider__slide flex lg:items-center flex-wrap">
                  <img alt="Novusa inventārs" className="w-[1200px] left-1/2 -translate-x-1/2 max-w-[1200px] lg:max-w-full lg:w-full pointer-events-none rounded-lg absolute opacity-10 top-1/2 -translate-y-1/2" src={require(`../assets/products/${newProduct.image}`)}></img> 
                  <img alt="Novusa inventārs" className="rounded-t-lg w-full max-h-[calc(100vw-32px)] lg:w-auto lg:max-h-[500px] inline-block lg:pr-6 z-10 relative" src={require(`../assets/products/${newProduct.image}`)}></img> 
                  <div className="block lg:inline-block p-4">
                    <div className="font-protest text-white text-[34px] leading-[34px] mb-6">{newProduct.name}</div>
                    <div className="text-white text-[16px] leading-[34px]" dangerouslySetInnerHTML={{ __html: newProduct.description}}>
                    </div>
                    {newProduct.price && (
                      <div className="font-protest text-white text-[20px] leading-[24px] mt-4">CENA: {newProduct.price}</div>
                    )}
                    <BuyButton/>
                  </div>
                </div>
              )}
              {loaded && keenInstanceRef.current && (
                <>
                  <div
                    className="left-arrow z-20 absolute top-1/2 left-0 w-7 h-7 rounded-full bg-white shadow-xl border-none transition-all hover:bg-opacity-70 cursor-pointer"
                    onClick={(e) =>
                      e.stopPropagation() || keenInstanceRef.current?.prev()
                    }
                    disabled={currentSlide === 0}
                  ><svg className="w-[24px] h-[24px] my-[2px] mx-[2px]" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M15 7L10 12L15 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg></div>

                  <div
                       className="right-arrow z-20 absolute top-1/2 right-0 w-7 h-7 rounded-full bg-white  shadow-xl border-none transition-all hover:bg-opacity-70 cursor-pointer rotate-180"
                    onClick={(e) =>
                      e.stopPropagation() || keenInstanceRef.current?.next()
                    }
                    disabled={
                      currentSlide ===
                      keenInstanceRef.current.track.details.slides.length - 1
                    }
                  ><svg className="w-[24px] h-[24px] my-[2px] mx-[2px]" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M15 7L10 12L15 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg></div>
                </>
              )}
            </div>  
          </div>
          <div ref={sliderRef} className="keen-slider w-full">
          {products.map((product, index) =>
          <div key={index} className="keen-slider__slide pb-12 relative w-full md:w-1/2 lg:w-1/5 border border-gray-200 rounded-lg shadow bg-darkMain">
            <img alt="Novusa galds" className="w-full rounded-t-lg" src={require(`../assets/products/${product.image}`)}></img>
            <div className="p-4">
            <h5 class="font-protest text-white text-[24px] leading-[34px] mb-6">{product.name}</h5>
            <span className="mb-3 font-normal  text-gray-400" dangerouslySetInnerHTML={{ __html: product.description}}></span>
              {product.price && (
                <div className="font-protest text-white text-[20px] leading-[24px] mt-4">CENA: {product.price}</div>
              )}
              <BuyButton/>
            </div>
          </div>
          )}
          {loaded && sliderInstanceRef.current && (
                <>
                  <div
                    className="left-arrow z-20 absolute top-[45%] left-0 w-7 h-7 rounded-full bg-white shadow-xl border-none transition-all hover:bg-opacity-70 cursor-pointer"
                    onClick={(e) =>
                      e.stopPropagation() || sliderInstanceRef.current?.prev()
                    }
                    disabled={sliderCurrentSlide === 0}
                  ><svg className="w-[24px] h-[24px] my-[2px] mx-[2px]" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M15 7L10 12L15 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg></div>

                  <div
                       className="right-arrow z-20 absolute top-[45%] right-0 w-7 h-7 rounded-full bg-white  shadow-xl border-none transition-all hover:bg-opacity-70 cursor-pointer rotate-180"
                    onClick={(e) =>
                      e.stopPropagation() || sliderInstanceRef.current?.next()
                    }
                    disabled={
                      sliderCurrentSlide ===
                      sliderInstanceRef.current.track.details.slides.length - 1
                    }
                  ><svg className="w-[24px] h-[24px] my-[2px] mx-[2px]" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M15 7L10 12L15 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg></div>
                </>
              )}
        </div>
        </section>
        <section id="buy" className="md:p-4 overflow-auto shadow-xl rounded-lg mt-10 mb-10 block max-w-screen-lg mx-auto buy-section bg-darkMain text-white">
        <div id="location" className="map-section w-full md:w-1/2 information-side float-left">
              <iframe 
              title="Google Maps"
              className="rounded-lg w-full" 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2148.352746598684!2d25.27895257721205!3d57.42121646226758!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46ebe5003a5e22e9%3A0x50ea37b9e67bb85!2sGaujas%20iela%2026%2C%20Vaidava%2C%20Vaidavas%20pagasts%2C%20LV-4228!5e0!3m2!1sen!2slv!4v1674576363934!5m2!1sen!2slv" 
              width="100%"
              height="400"
              style={{ border: "0"}} 
              allowFullScreen="" 
              loading="lazy" 
              referrerPolicy="no-referrer-when-downgrade">
              </iframe>
            </div>
            <div className="w-full p-4 md:pl-8 md:w-1/2 information-side float-right">
            <span className="border-b-2 font-protest text-white  text-xl">Kā pasūtīt?</span>
            <span className="block mt-4">
              Novusa inventāra pasūtīšana pagaidām nav pieejama caur tīmekļa vietni, tāpēc, lai to iegādātos, sazinaties ar meistaru - Aināru Pēču.
            </span>
            <span className="mt-4 block font-protest text-white ">Sazinies:</span>
            <span className="mt-4 block font-protest text-white ">Zvanot: <a className="hover:text-novusOak-500 focus:text-novusOak-500 " href="tel: +37129240819">29240819</a></span>
            <span className="mt-4 block font-protest text-white  text-l">Rakstot: <a className="hover:text-novusOak-500 focus:text-novusOak-500 " href="mailto: pica1968@inbox.lv">pica1968@inbox.lv</a></span>
            <span className="mt-4 block font-protest text-white  text-l">Atrodamies: <span className="">Valmieras novads, Vaidava, Gaujas iela 26</span></span>
          </div>
        </section>
        <section id="information" className="md:p-4 overflow-auto shadow-xl rounded-lg mt-10 mb-10 block max-w-screen-lg mx-auto information-section bg-darkMain">
          <div className="w-full p-4 md:pr-8 md:w-1/2 information-side float-left">
            <span className="border-b-2 font-protest text-white  text-xl ">Par Meistaru</span>

            <span className="block mt-4 font-protest text-white">
              Meistars, Ainārs Pēčs, galdus izgatavo vairāk kā 10 gadus. 
            </span>
          </div>
          <div className="w-full md:w-1/2 information-side float-right">
            <iframe
                className="rounded-lg w-full" 
                width="560" 
                height="400" 
                src="https://tube.rvere.com/embed?v=udnxA5EdVFY&start=0&end=1697"
                title="YouTube video player"
                frameBorder="0"
                loading="lazy" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen>
            </iframe>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default HomeScreen;
