import React, { useEffect, useState } from "react";
import Logo from "../assets/LogoSimpleLight.svg";
import AnchorLink from 'react-anchor-link-smooth-scroll-v2'

const Header = () => {
    const [className, setClassName] = useState('');
    const [stickyHeader, setStickHeader] = useState(false);

    function handleBurger(e) {
        e.preventDefault();

        if (className === '') {
            setClassName('burger-opened');
        } else {
            setClassName('');
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });

           
    /* Method that will fix header after a specific scrollable */
    const isSticky = (e) => {
        const header = document.querySelector('.header');
        const scrollTop = window.scrollY;
        scrollTop >= 72 ? setStickHeader(true) : setStickHeader(false);
    };

    return (
        <div className={className === 'burger-opened' ? className + ' top-part relative z-50' : 'top-part relative z-50' }>
            <div className={+ stickyHeader ? 'header backdrop-blur-sm z-10 fixed w-full  bg-darkMain bg-opacity-50 transition-all' : 'header backdrop-blur-sm z-10 fixed w-full transition-all'}>
                <div className="burger-menu">
                    <button aria-label="Hamburger" className="cursor-pointer relative group" onClick={handleBurger}>
                        <div className="relative flex overflow-hidden items-center justify-center rounded-full w-[50px] h-[50px] transform transition-all">
                        <div className="flex flex-col justify-between w-[20px] h-[20px] transform transition-all duration-300 origin-center overflow-hidden">
                            <div className="not-focused bg-white h-[2px] w-7 transform transition-all duration-300 origin-left delay-100"></div>
                            <div className="not-focused  bg-white h-[2px] w-7 rounded transform transition-all duration-300 delay-75"></div>
                            <div className="not-focused  bg-white h-[2px] w-7 transform transition-all duration-300 origin-left"></div>
                            <div className="focused absolute items-center justify-between transform transition-all duration-500 top-2.5 -translate-x-10 group-focus:translate-x-0 flex w-0">
                            <div className="focused  absolute bg-white h-[2px] w-5 transform transition-all duration-500 rotate-0 delay-300"></div>
                            <div className="focused back absolute bg-white h-[2px] w-5 transform transition-all duration-500 -rotate-0 delay-300"></div>
                            </div>
                        </div>
                        </div>
                    </button>
                </div>

                <nav className="mx-auto navbar navbar-expand-lg max-w-screen-lg py-2 relative flex items-center w-full justify-between">
                    <div className="px-6 md:px-0 w-full flex flex-wrap items-center justify-between">
                        <img alt="Novusa Galdi no Vaidavas" className="h-12 absolute" width="126" height="48" src={Logo}/>
                        <div className="py-2 navbar-collapse grow items-center" id="navbarSupportedContentY">
                            <ul className="navbar-nav mr-auto lg:flex lg:flex-row justify-center text-center">
                                <li onClick={handleBurger} className="nav-item">
                                <AnchorLink offset='100' className="text-white uppercase font-semibold nav-link block pr-2 lg:px-4 py-2 hover:text-slate-200 focus:text-slate-200 transition duration-150 ease-in-out"
                                    href="#products">Inventārs</AnchorLink>
                                </li>
                                <li onClick={handleBurger} className="nav-item">
                                <AnchorLink offset='100' className="text-white uppercase font-semibold nav-link block pr-2 lg:px-4 py-2 hover:text-slate-200 focus:text-slate-200 transition duration-150 ease-in-out"
                                    href="#buy">Iegādāties</AnchorLink>
                                </li>
                                <li onClick={handleBurger} className="nav-item mb-2 lg:mb-0">
                                <AnchorLink offset='100' className="text-white uppercase font-semibold nav-link block pr-2 lg:px-4 py-2 hover:text-slate-200 focus:text-slate-200 transition duration-150 ease-in-out"
                                    href="#information">Meistars</AnchorLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
};

export default Header;