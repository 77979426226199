const BuyButton = () => {

    return (
        <a href="#buy" class="absolute left-4 bottom-3 font-protest inline-flex min-h-8 py-3 transition-all items-center px-3 text-[16px] mt-4 text-sm text-center text-white bg-novusOak rounded-lg hover:bg-novusOak-500 focus:ring-4 focus:outline-none ">
            Iegādāties
            <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2 ml-1 inline-block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
            </svg>
        </a>
    )
}

export default BuyButton;